import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash-es';
import classNames from 'classnames';
import { Icon } from '@iconify-icon/react';

const propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isIndeterminate: PropTypes.bool,
  onChange: PropTypes.func,
};
const defaultProps = {
  isChecked: false,
  isDisabled: false,
  isIndeterminate: false,
  onChange: () => {},
};

function Checkbox({ isChecked, isDisabled, isIndeterminate, onChange }) {
  const input = useRef();
  const id = uniqueId('checkbox-');
  const className = classNames(
    'custom-checkbox',
    { 'custom-checkbox--checked': isChecked },
    { 'custom-checkbox--disabled': isDisabled },
  );

  // Functions
  function getIcon() {
    switch (true) {
      case isIndeterminate && isChecked:
        return 'fa:minus-square';
      case isChecked:
        return 'fa:check-square';
      default:
        return 'fa:square-o';
    }
  }

  function handleOnClick(e) {
    e.preventDefault();
    onChange(e);
    document.getSelection().removeAllRanges(); // Deselect the default selection when shift key is pressed.
  }

  // Effects
  useEffect(() => {
    if (input.current) {
      input.current.indeterminate = isIndeterminate;
    }
  }, [input, isIndeterminate]);

  // Render
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
    <label className={className} htmlFor={id} onClick={handleOnClick}>
      <input
        id={id}
        name={id}
        className="sr-only"
        type="checkbox"
        checked={isChecked}
        disabled={isDisabled}
        readOnly
        ref={input}
      />
      <span className="custom-checkbox__indicator">
        <Icon icon={getIcon()} width="14" height="14" />
      </span>
    </label>
  );
}

Checkbox.propTypes = propTypes;
Checkbox.defaultProps = defaultProps;

export default Checkbox;
